<template lang="pug">
    section#arquivos
        .wrapper
            .controls(v-if="contents.content.length > 6")
                button.left(@click="moveGaleria(posicao - 1)" :disabled="posicao == 0")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
                button.right(@click="moveGaleria(posicao + 1)" :disabled="posicao >= pages")
                    SvgIcon(data="@svgs/seta-ippuc.svg")
            .galeria
                ul(:style="galleryStyle").items
                    li(v-for="item in contents.content").item
                        a(:href="getLink(item)", target="_blank")
                            Imagem(:contents="item.image", :class="{ caderno: item.magazineType == 'caderno' }")
                        //- .content
                            h3 {{ item.title }}
                            ul.links
                                li.link
                                    a(:href="getLink(item)", target="_blank") Visualizar
                                //- li.link
                                    a(:href="getLink(item)", download, target="_blank") Baixar
</template>

<script>
import { components, props } from '@/mixins/components.js'

export default {
    name: "section-arquivos",
    components,
    props,
    data() {
        return {
            posicao: 0,
        }
    },
    computed: {
        galleryStyle() {
            return {
                transform: `translateX(-${100 * this.posicao}%)`
            }
        },
        pages() {
            return Math.ceil(this.contents.content.length / 7)
        },
    },
    methods: {
        getLink(item) {
            if (item.isExternal)
                return item.link
            return item.file
        },
        moveGaleria(index) {
            if (index >= this.contents.content.length)
                this.posicao = this.contents.content.length - 1
            else if (index < 0)
                this.posicao = 0
            else
                this.posicao = index
        }
    },
}
</script>

<style lang="stylus" scoped src="./Arquivos.styl"></style>
